import React from "react"
import Img from 'gatsby-image'
function PortfolioModal({ project }) {
  let { media } = project

  media = media.filter(m => !m.featured)
  return (
    <div
      className="modal fade portfolio-modal"
      id={"portfolioModal-" + project.strapiId}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body col-md-11 col-lg-9 ml-auto mr-auto">
            <p className="portfolio-modal__title">{project.title}</p>
            <div
              id={`indicators-project-${project.strapiId}`}
              class="carousel slide"
              data-ride="carousel"
              data-interval="2000"
            >
              <ol class="carousel-indicators">
                {media.map((m, i) => (
                  <li
                    data-target={`#indicators-project-${project.strapiId}`}
                    data-slide-to={i}
                    class={i == 0 ? "active" : ""}
                  ></li>
                ))}
              </ol>
              <div class="carousel-inner">
                {media.map((image, i) => (
                  <div
                    class={`carousel-item ${i == 0 ? "active" : ""}`}
                    data-interval="2000"
                  >
                    {/* <img class="d-block w-100" src="..." /> */}
                    <Img
                      className="d-block w-100"
                      fluid={image.file.localFile.childImageSharp.fluid}
                      alt="modal_img"
                    />
                  </div>
                ))}
              </div>
              <a
                class="carousel-control-prev"
                href={`#indicators-project-${project.strapiId}`}
                role="button"
                data-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Previous</span>
              </a>
              <a
                class="carousel-control-next"
                href={`#indicators-project-${project.strapiId}`}
                role="button"
                data-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="sr-only">Next</span>
              </a>
            </div>
            <p className="portfolio-modal__description">
              {project.description}
            </p>
            <div className="portfolio-modal__link">
              <a href={project.url}>{project.display_url}</a>
            </div>
            <div className="portfolio-modal__stack">
              <p className="portfolio-modal__stack-title">Using stack:</p>
              <ul className="tags">
                {project.stack.map(stack => (
                  <li key={stack.id}>{stack.name}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioModal
