import React, { useEffect } from 'react'
import Img from 'gatsby-image'
import BackgroundImage from "gatsby-background-image"
import $ from 'jquery'
import { graphql, useStaticQuery } from 'gatsby'

const API_URL = process.env.API_URL
const query = graphql`
  {
    allFile(filter: { name: { eq: "img_bg_main" } }) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
function Header({personalDetails}) {
    const data = useStaticQuery(query).allFile.edges[0].node.childImageSharp.fluid
    useEffect(() => {
      console.log(personalDetails)
      // $('.main-header').css('background-image', `url(${API_URL + (personalDetails && personalDetails.avatar && personalDetails.avatar.formats.small.url)})`)
      return () => {
      }
    }, [personalDetails])
    return (
      // <BackgroundImage
      //   Tag="section"
      //   className={"main-header"}
      //   fluid={data}
      //   backgroundColor={`#4a4a4a`}
      //   style={{
      //     // Defaults are overwrite-able by setting one or each of the following:
      //     backgroundSize: "",
      //     backgroundPosition: "",
      //     backgroundRepeat: "",
      //   }}
      // >
      <header className="main-header" style={{backgroundImage: 'url(img_bg_main.webp)'}}>
        <div className="container">
          <div className="row personal-profile">
            {/* <div className="col-md-4 personal-profile__avatar">
              {personalDetails.avatar && (
                <Img
                  className=""
                  style={{borderRadius: '5px', boxShadow: '0 0 27px rgba(96,96,96,.34)', width: '90%', 'height': '380px', '-o-object-fit':'cover', objectFit: 'cover', marginTop: '10px', position: 'relative', top:'40px'}}
                  fluid={personalDetails.avatar.localFile.childImageSharp.fluid}
                  alt="avatar"
                />
              )}
            </div> */}
            <div className="col-md-8">
              <h1 className="personal-profile__name">{personalDetails.name}_</h1>
              <p className="personal-profile__work">{personalDetails.roles}</p>
              <div className="personal-profile__contacts">
                <dl className="contact-list contact-list__opacity-titles">
                  <dt>Age:</dt>
                  <dd>{personalDetails.age}</dd>
                  {/* <dt>Phone:</dt>
              <dd><a href="tel:82344563333">8 (234) 456-33-33</a></dd> */}
                  <dt>Email:</dt>
                  <dd>
                    <a href={"mailto:" + personalDetails.email}>
                      {personalDetails.email}
                    </a>
                  </dd>
                  <dt>Address:</dt>
                  <dd>{personalDetails.address}</dd>
                </dl>
              </div>
              <p className="personal-profile__social">
                {personalDetails.social_link &&
                  personalDetails.social_link.map(link =>
                    link.name !== "upwork" ? (
                      <a
                        key={link.name}
                        href={link.url}
                        target="_blank"
                        aria-label={link.name}
                        rel="noreferrer"
                      >
                        <i className={"fa fa-" + link.name}></i>
                      </a>
                    ) : (
                      <a
                        key={link.name}
                        href={link.url}
                        target="_blank"
                        aria-label={link.name}
                        rel="noreferrer"
                        style={{ textDecoration: "none" }}
                      >
                        <i
                          className={"ic-upwork"}
                          style={{ fontSize: "1.2em" }}
                        ></i>
                      </a>
                    )
                  )}
              </p>
            </div>
          </div>
        </div>
      </header>
      // </BackgroundImage>
    )
}

export default Header
