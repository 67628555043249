import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import $ from 'jquery'
import PortfolioModal from './PortfolioModal';
import Img from 'gatsby-image'

const query = graphql`
  {
    allStrapiProjects(
      filter: {
        featured: { eq: true }
        media: { elemMatch: { featured: { eq: true } } }
      },
      sort: {
        fields: order,
        order: ASC
      }
    ) {
      edges {
        node {
          strapiId
          featured
          description
          display_url
          media {
            featured
            file {
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            order
          }
          order
          stack {
            id
            name
          }
          title
          url
        }
      }
    }
  }
`

function intitializePortfolioFilter() {
  let previousClickedMenuLink = undefined;
$('.portfolio-menu').on('click', 'a', function(event){
    event.preventDefault();
    if (previousClickedMenuLink) {
        previousClickedMenuLink.removeClass('portfolio-menu__link--active');
    }
    var link = $(event.target);
    link.addClass('portfolio-menu__link--active');
    previousClickedMenuLink = link;

    var targetTag = $(event.target).data('portfolio-target-tag');
    var portfolioItems = $('.portfolio-cards').children();

    if (targetTag === 'all') {
        portfolioItems.fadeIn({duration: 500});
    } else {
        portfolioItems.hide();
    }

    portfolioItems.each(function(index, value){
        var item = $(value);
        if (item.data('portfolio-tag') === targetTag) {
            item.fadeIn({duration: 500});
        }
    });
});
}

function Portfolio() {
  const [projects, setProjects] = useState([])
  const data = useStaticQuery(query)
  useEffect(() => {
    intitializePortfolioFilter()
    setProjects(data['allStrapiProjects']['edges'])
    return () => {
      
    }
  }, [data])
    return (
      <section id="portfolio" className="container section">
        <div className="row">
          <div className="col-md-12">
            <h2 id="portfolio_header" className="section__title">
              My projects_
            </h2>
          </div>
        </div>
        <div className="row portfolio-menu">
          <div className="col-md-12">
            <nav>
              <ul>
                <li>
                  <a href="#" data-portfolio-target-tag="all">
                    all
                  </a>
                </li>
                <li>
                  <a href="#" data-portfolio-target-tag="mobile apps">
                    mobile apps
                  </a>
                </li>
                <li>
                  <a href="#" data-portfolio-target-tag="web-sites">
                    web-sites
                  </a>
                </li>
                <li>
                  <a href="#" data-portfolio-target-tag="landing-pages">
                    landing pages
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="portfolio-cards">
          {projects.map(project => (
            <div key={project.node.strapiId}>
              <div
                className="row project-card"
                data-toggle="modal"
                data-target={"#portfolioModal-" + project.node.strapiId}
                data-portfolio-tag="web-sites"
              >
                <div className="col-md-6 col-lg-5 project-card__img">
                  <Img
                    className=""
                    style={{ height: "100%" }}
                    fluid={project.node.media[0].file.localFile.childImageSharp.fluid}
                    alt="project-img"
                  />
                </div>
                <div className="col-md-6 col-lg-7 project-card__info">
                  <h3 className="project-card__title">{project.node.title}</h3>
                  <p className="project-card__description">
                    {project.node.description}
                  </p>
                  <p className="project-card__stack">Used stack:</p>
                  <ul className="tags">
                    {project.node.stack.map(item => (
                      <li key={item.id}>{item.name}</li>
                    ))}
                  </ul>
                  <a href={project.node.url} className="project-card__link">
                    {project.node.display_url}
                  </a>
                </div>
              </div>
              <PortfolioModal project={project.node} />
            </div>
          ))}
        </div>
      </section>
    )
}

export default Portfolio
