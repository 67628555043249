import React, {useEffect} from 'react'
import $ from 'jquery'
import {graphql, useStaticQuery} from 'gatsby'
import { useState } from 'react'

const query = graphql`
{
  allStrapiTestimonials(sort: {fields: order, order: ASC}) {
    edges {
      node {
        client
        content
        platform
        project
        order
      }
    }
  }
}
`


function Testimonials() {
  const data = useStaticQuery(query)

  const [testimonials, setTestimonials] = useState([])

    useEffect(() => {
      setTestimonials(data['allStrapiTestimonials']['edges'])
        $('.carousel').carousel({
    pause: "hover",
    interval: 5000
});

$(".carousel-control-prev").click(function(){
    $(".carousel").carousel("prev");
});

$(".carousel-control-next").click(function(){
    $(".carousel").carousel("next");
});
        return () => {
        }
    }, [testimonials])
    return (
    <div id="testimonials" className="section">
    <div className="background slider-carousel" style={{backgroundImage: 'url(img_bg_main.webp)'}}>
      <div className="container">
        <div id="carouselTestimonials" className="carousel slide" data-ride="carousel">
          <ol className="carousel-indicators">
            {testimonials.map((_, index) => (
              _ && <li key={index} data-target="#carouselTestimonials" data-slide-to={index} className={index === 0 ? "active" : ""}></li>
            ))}
          </ol>
          <div className="carousel-inner" role="listbox">
            {testimonials.map(testimonial =>(
            testimonial &&
            <div key={testimonial.node.order} className={"carousel-item " + (testimonial.node.order == 1 ? "active" : "")}>
              <div className="row">
                <div className="col-md-10 col-sm-10 col-10 mr-auto ml-auto">
                  <p className="slider-carousel__title">{testimonial.node.client}</p>
                  {/* <p className="slider-carousel__caption">Project: {testimonial.node.project}</p> */}
                  <p className="slider-carousel__caption">{testimonial.node.platform}</p>
                  <hr />
                  <p className="slider-carousel__description">{testimonial.node.content}</p>
                </div>
              </div>
            </div>

            ))}
          </div>
          <a className="carousel-control-prev" href="#testimonials" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#testimonials" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
          <div className="slider-carousel__circle">
            <p><i className="fa fa-quote-right" aria-hidden="true"></i></p>
          </div>
        </div>
      </div>
    </div>
  </div>
    )
}

export default Testimonials
