import React, { useEffect } from 'react'
import $ from 'jquery'

function innitializeMobileMenu() {
//Open mobile menu
  $('.menu__mobile-button, .mobile-menu__close').on('click', function () {
      $('.mobile-menu').toggleClass('active');
  });

  //Close mobile menu after click
  $('.mobile-menu__wrapper ul li a').on('click', function () {
      $('.mobile-menu').removeClass('active');
  });
}
//All ids of titles should be written here to animation work


function animateToSections() {
  $("a[href^='#']").click(function(){
        var target = $(this).attr('href');
        if (target !== '#') {
          $('html, body').animate({scrollTop: $(target).offset().top - 50}, 800);
          return false;

        }
    });
}

function fixedHeader() {
    var ww = $(window).scrollTop();
    if(ww > 0){
        $('.menu').addClass('menu--active');
        $('.mobile-menu').addClass('mobile-menu--active');
    }else{
        $('.menu').removeClass('menu--active');
        $('.mobile-menu').removeClass('mobile-menu--active');
    }
}

function NavBar() {
  useEffect(() => {
    innitializeMobileMenu()
    fixedHeader()
    animateToSections()
    $(window).on('scroll', function () {
    fixedHeader();
    });
    return () => {
    
    }
  }, [])
    return (
        <div>
    <div className="menu">
        <div className="container">
        <div className="row">
            <div className="menu__wrapper d-none d-lg-block col-md-12">
            <nav className="">
                <ul>
                <li><a href="#hello">Hello</a></li>
                <li><a href="#resume">Resume</a></li>
                <li><a href="#portfolio">Portfolio</a></li>
                <li><a href="#testimonials">testimonials</a></li>
                {/* <li><a href="#blog">blog</a></li> */}
                <li><a href="#contact">Contact</a></li>
                </ul>
            </nav>
            </div>
            <div className="menu__wrapper col-md-12 d-lg-none">
            <button type="button" className="menu__mobile-button">
                <span><i className="fa fa-bars" aria-hidden="true"></i></span>
            </button>
            </div>
        </div>
        </div>
    </div>
    <div className="mobile-menu d-lg-none">
    <div className="container">
      <div className="mobile-menu__close">
        <span><i className="mdi mdi-close" aria-hidden="true"></i></span>
      </div>
      <nav className="mobile-menu__wrapper">
        <ul>
          <li><a href="#hello">Hello</a></li>
          <li><a href="#resume">Resume</a></li>
          <li><a href="#portfolio">Portfolio</a></li>
          <li><a href="#testimonials">testimonials</a></li>
          <li><a href="#blog">blog</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
      </nav>
    </div>
  </div>
    </div>
    )
}

export default NavBar
