import React from 'react'

function ResumeItem({item}) {
    return (
        <div key={item.strapiId} className="resume-list__block">
          <p className="resume-list__block-title">{item.company || item.institution}</p>
          <p className="resume-list__block-date">{item.date}</p>
          <p>
            {item.title}<br></br>
            <pre>{item.description}</pre>
          </p>
        </div>
    )
}

export default ResumeItem
