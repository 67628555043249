import React, { useEffect } from 'react'
import $ from 'jquery'
function validateForm(selector) {
    Array.from(document.querySelectorAll(selector)).forEach(item => {
        item.addEventListener('input', (e) => {
            if(e.target.value === ''){
            item.dataset.touched = false;
            }
        });
        item.addEventListener('invalid', () => {
            item.dataset.touched = true;
        });
        item.addEventListener('blur', () => {
            if (item.value !== '') item.dataset.touched = true;
        });
    });
};
function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}
function initializeForm() {

  validateForm('.js-form .form-field');

  var form = document.querySelector('.js-form');
  var formName = '.js-form';

  form.addEventListener('submit', function(e){
    submitForm(e, formName);
});
}


function submitForm(e, formName) {
    e.preventDefault();
    var name = $(formName + ' .js-field-name').val();
    var email = $(formName + ' .js-field-email').val();
    var message = $(formName + ' .js-field-message').val();

    var formData = {
        name: name,
        email: email,
        message: message
    };

    $.ajax({
      method: 'POST',
      url: 'https://www.enformed.io/p2zollwu/',
      dataType: 'json',
      accepts: 'application/json',
      data: formData,
      success: (data) => console.log(data),
      error: (err) => console.log(err)
    });
}

function Contact({personalDetails}) {
  useEffect(() => {
    initializeForm()
    return () => {
    
    }
  }, [])
    return (
          <div className="background" style={{backgroundImage: 'url("img_bg_main.webp")' }} >
    <div id="contact" className="container section">
      <div className="row">
        <div className="col-md-12">
          <p id="contacts_header" className="section__title">Get in touch_</p>
        </div>
      </div>
      <div className="row contacts">
        <div className="col-md-5 col-lg-4">
          <div className="contacts__list">
            <dl className="contact-list">
              {/* <dt>Phone:</dt> */}
              {/* <dd><a href="tel:82344563333">8 (234) 456-33-33</a></dd> */}
              <dt>Email:</dt>
              <dd>{personalDetails && <a href={'mailto:' + personalDetails.email}>{personalDetails.email}</a>}</dd>
              <dt>Skype:</dt>
              <dd><a href="skype:live:muhammadasimwaqar">live:muhammadasimwaqar</a></dd>
            </dl>
          </div>
          <div className="contacts__social">
            <ul>
              {personalDetails.social_link && personalDetails.social_link.map(link =>(
                <li key={link.id}>
                <a href={link.url} target="_blank" aria-label={link.name} rel="noreferrer">
                  {toTitleCase(link.name)}
                </a>
                </li>
            ))}
            </ul>
          </div>
        </div>
        <div className="col-md-7 col-lg-5">
          <div className="contacts__form">
            <p className="contacts__form-title">Or just write me a letter here_</p>
            <form className="js-form">
              <div className="form-group">
                <input className="form-field js-field-name" type="text" placeholder="Your name" required />
                <span className="form-validation"></span>
                <span className="form-invalid-icon"><i className="mdi mdi-close" aria-hidden="true"></i></span>
              </div>
              <div className="form-group">
                <input className="form-field js-field-email" type="email"  placeholder="Your e-mail" required />
                <span className="form-validation"></span>
                <span className="form-invalid-icon"><i className="mdi mdi-close" aria-hidden="true"></i></span>
              </div>
              <div className="form-group">
                <textarea className="form-field js-field-message" placeholder="Type the message here" required></textarea>
                <span className="form-validation"></span>
                <span className="form-invalid-icon"><i className="mdi mdi-close" aria-hidden="true"></i></span>
              </div>
              <button className="site-btn site-btn--form ripple" type="submit" value="Send">Send</button>
            </form>
          </div>
          <div className="footer">
            <p>© {new Date().getFullYear()} Asim Hashmi. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </div>
  </div>
    )
}

export default Contact
