import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import { Waypoint } from 'react-waypoint'
import { graphql, useStaticQuery } from 'gatsby'
import ResumeItem from './ResumeItem'
const query = graphql`
  {
    allStrapiJobs(sort: { order: DESC, fields: strapiId}) {
      nodes {
        strapiId
        company
        date
        title
        description
      }
    }
    allStrapiEducations(sort: { order: DESC, fields: strapiId}) {
    nodes {
      description
      strapiId
      institution
      title
      date
    }
  }
  allStrapiSkills(sort: { order: ASC, fields: strapiId}) {
    nodes {
      strapiId
      name
      proficiency
    }
  }
}
`

function animateProgressBar() {
    $(".progress-bar").each(function () {
      $(this).animate({
          width: $(this).attr('aria-valuenow') + '%'
      }, 200);
    });
}

function Resume() {
    const [jobs, setJobs] = useState([])
    const [educations, setEducations] = useState([])
    const [skills, setSkills] = useState([])
    const data = useStaticQuery(query)
    useEffect(() => {      
      setJobs(data['allStrapiJobs']['nodes'])
      setEducations(data['allStrapiEducations']['nodes'])
      setSkills(data['allStrapiSkills']['nodes'])

      return () => {
      }
    }, [data])
    return (
        <section id="resume" className="container section">
    <div className="row">
      <div className="col-md-10">
        <h2 id="resume_header" className="section__title">Resume_</h2>
        <p className="section__description">
        </p>
      </div>
    </div>
    <div className="row">
      <div className="col-md-8 section__resume resume-list">
        <h3 className="resume-list_title">education</h3>
        {educations.map(education => <ResumeItem key={education.strapiId} item={education} />)}
      </div>
    </div>
    <div className="row">
      <div className="col-md-8 section__resume resume-list">
        <h3 className="resume-list_title">employment</h3>
        {jobs.map(job => <ResumeItem key={job.strapiId} item={job} />)}
      </div>
    </div>
    <Waypoint onEnter={animateProgressBar} />
    <div className="row section__resume progress-list js-progress-list">
      <div className="col-md-12">
        <h3 className="progress-list__title">general skills</h3>
      </div>
      {skills.map(skill => (
      <div key={skill.strapiId} className="col-md-5 mr-auto">
        <div className="progress-list__skill">
          <p>
            <span className="progress-list__skill-title">{skill.name}</span>
            <span className="progress-list__skill-value">{skill.proficiency}%</span>
          </p>
          <div className="progress">
            <div className="progress-bar" role="progressbar" aria-valuenow={skill.proficiency} aria-valuemin="0" aria-valuemax="100" >
            </div>
          </div>
        </div>
      </div>
      ))}
    </div>
 </section>
    )
}

export default Resume
