import React, { useState, useEffect } from "react"
import Contact from "../components/Contact"
import Header from "../components/Header"
import Hello from "../components/Hello"
import NavBar from "../components/NavBar"
import Portfolio from "../components/Portfolio"
import PortfolioModal from "../components/PortfolioModal"
import Resume from "../components/Resume"
import {graphql, useStaticQuery} from 'gatsby'
import $ from 'jquery'
import { Waypoint } from 'react-waypoint'
import Testimonials from "../components/Testimonials"
//Animate headers of .section
var hideHeader = function(header) {
    header.css('text-indent', '-9999px');
};

var showHeader = function(header) {
    header.css('text-indent', '0px');
};

var animateHeader = function(header, text) {
    //clear header text
    header.text("");
    //and animate it
    var nextAnimationStep = function() {
        if (text.length > 0) {
            header.text(header.text() + text.substr(0,1));
            text = text.substr(1);
            setTimeout(nextAnimationStep, 100);
        }
    };
    nextAnimationStep();
};
const animateSectionHeader = (id) => {
    const header = $(id)
    showHeader(header);
    animateHeader(header, header.text());
}
var animateHeaders = function(headers) {
    return Object.keys(headers).map(function(key, index) {
        var elementSelector = key;
        var offset = headers[key];
        var header = $(elementSelector);
        hideHeader(header);
    }).reduce(Object.assign, {});
};

const query = graphql`
  {
    allStrapiPersonalDetails {
      edges {
        node {
          email
          avatar {
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          address
          age
          intro
          name
          roles
          social_link {
            name
            url
            id
          }
        }
      }
    }
  }
`

export default function Home() {
  const [personalDetails, setPersonalDetails] = useState({})
  const data = useStaticQuery(query)
  useEffect(() => {
    animateHeaders({
    "#hello_header": '90%',
    "#resume_header": '70%',
    "#portfolio_header": '70%',
    "#testimonials_header": '70%',
    "#blog_header": '70%',
    "#contacts_header": '70%',
    "#other_posts": '70%'
});
    setPersonalDetails(data['allStrapiPersonalDetails']['edges'][0]['node'])
    return () => {
    
    }
  }, [data])
  return <div>
    <NavBar />
    <Header personalDetails={personalDetails} />
    <Waypoint onEnter={() => animateSectionHeader('#hello_header')} />
    <Hello personalDetails={personalDetails} />
    <hr />
    <Waypoint onEnter={() => animateSectionHeader('#resume_header')} />
    <Resume />
    <Waypoint onEnter={() => animateSectionHeader('#portfolio_header')} />
    <Portfolio />
    <Testimonials />
    <Waypoint onEnter={() => animateSectionHeader('#contacts_header')} />
    <Contact personalDetails={personalDetails} />
  </div>
}
